<script>

export default {
  name: "Now",
  props: {
    lastUpdate: Number,
    timeoutLastUpdate: Number,
    text: String
  },

  data() {
    return {
      now: 0,
    }
  },
  created() {
    setInterval(() => {
      this.now = Math.round(Date.now() / 1000)
      // this.$emit('now', this.now)
    }, 1000)
  }
}
</script>

<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-if="(now - lastUpdate)/60 > timeoutLastUpdate" v-on="on" class="error--text">{{ text }}</span>
      <span v-else v-on="on">{{ text }}</span>
    </template>

     <span>Updated {{ (Math.round((now - lastUpdate) / 60)) }} minutes ago</span>

  </v-tooltip>
</template>

<style scoped>

</style>