<template>
  <v-container fluid>
    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        right
        top
        color="green darken-2"
    >
      Copied : {{ snackbarText }}
    </v-snackbar>
    <v-dialog v-model="isVisibleValidationErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Offer error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6" v-html="errorMessage"></p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--    <v-dialog v-model="confirmStartStopCampaign" max-width="1000px" v-if="toStartCampaign !== null">-->
    <!--      <v-card>-->
    <!--        <v-card-title class="green" v-if="toStartCampaign.status_name === 'paused'">-->
    <!--          <span class="headline">Start: <b>{{ toStartCampaign.campaign_id }}</b></span>-->
    <!--        </v-card-title>-->

    <!--        <v-card-title class="red" v-if="toStartCampaign.status_name === 'active'">-->
    <!--          <span class="headline">Stop: <b>{{ toStartCampaign.campaign_id }}</b></span>-->
    <!--        </v-card-title>-->

    <!--        <v-card-text>-->
    <!--          <v-container>-->
    <!--            <v-row>-->
    <!--              <v-col cols="12" sm="12" md="12" v-if="toStartCampaign.status_name === 'active'">-->
    <!--                <span class="headline"><b>{{ toStartCampaign.campaign_id }}</b> :  {{ toStartCampaign.name }}</span>-->
    <!--              </v-col>-->
    <!--              <v-col cols="12" sm="12" md="12" v-if="toStartCampaign.status_name === 'paused'">-->
    <!--                <span class="headline"><b>{{ toStartCampaign.campaign_id }}</b> :  {{ toStartCampaign.name }}</span>-->
    <!--              </v-col>-->
    <!--            </v-row>-->
    <!--          </v-container>-->
    <!--        </v-card-text>-->

    <!--        <v-card-actions>-->
    <!--          <v-spacer></v-spacer>-->
    <!--          <v-btn color="primary" text @click="toStartCampaign = null; confirmStartStopCampaign = false">Cancel</v-btn>-->
    <!--          <v-btn color="primary" v-if="toStartCampaign.status_name === 'active'"-->
    <!--                 @click="toStartCampaign = null; confirmStartStopCampaign = false">-->
    <!--            Yes-->
    <!--          </v-btn>-->

    <!--          <v-btn color="primary" v-if="toStartCampaign.status_name === 'paused'"-->
    <!--                 @click="toStartCampaign = null; confirmStartStopCampaign = false">-->
    <!--            Yes-->
    <!--          </v-btn>-->
    <!--          <v-spacer></v-spacer>-->
    <!--        </v-card-actions>-->

    <!--      </v-card>-->
    <!--    </v-dialog>-->

    <v-row
        justify="start"
        align="start"
        width="100%"
        class="ml-3 pr-4"
    >
      <v-row>
        <!--        <v-col cols="12" sm="1" md="1">-->
        <!--          <v-menu-->
        <!--              ref="menu"-->
        <!--              v-model="menu"-->
        <!--              :close-on-content-click="false"-->
        <!--              :return-value.sync="dates"-->
        <!--              transition="scale-transition"-->
        <!--              offset-y-->
        <!--              min-width="430px"-->
        <!--          >-->
        <!--            <template v-slot:activator="{ on, attrs }">-->
        <!--              <v-text-field-->
        <!--                  label="Date or Date range"-->
        <!--                  v-model="dateRangeText"-->
        <!--                  prepend-icon="mdi-calendar"-->
        <!--                  @click:prepend="menu = true"-->
        <!--                  readonly-->
        <!--                  v-bind="attrs"-->
        <!--                  v-on="on"-->
        <!--              ></v-text-field>-->

        <!--            </template>-->
        <!--            <v-card flat>-->
        <!--              <v-row no-gutters>-->
        <!--                <v-col cols="4">-->

        <!--                  <v-list dense>-->
        <!--                    &lt;!&ndash;                    <v-subheader class="justify-center"><v-icon >mdi-clock-fast</v-icon></v-subheader>&ndash;&gt;-->
        <!--                    <v-subheader class="justify-center">-->
        <!--                      <v-icon>mdi-calendar-range-outline</v-icon>-->
        <!--                    </v-subheader>-->
        <!--                    <v-list-item-group-->
        <!--                        v-model="selectedFastDateRange"-->
        <!--                        color="primary"-->
        <!--                    >-->
        <!--                      <v-list-item-->
        <!--                          v-for="(item, i) in selectedFastDateRangeItems"-->
        <!--                          :key="i"-->
        <!--                      >-->
        <!--                        &lt;!&ndash;                        <v-list-item-icon>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                          <v-icon v-text="item.icon"></v-icon>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                        </v-list-item-icon>&ndash;&gt;-->
        <!--                        <v-list-item-content @click="onClickFastDateInterval(item.value)">-->
        <!--                          <v-list-item-title v-text="item.text"></v-list-item-title>-->
        <!--                        </v-list-item-content>-->
        <!--                      </v-list-item>-->
        <!--                    </v-list-item-group>-->

        <!--                  </v-list>-->
        <!--                  <v-switch-->
        <!--                      v-if="$store.getters['user/isAdmin']"-->
        <!--                      v-model="switch1" label="Refresh" class="pl-6" color="red"-->
        <!--                      :disabled="isRunningBackdatingUpdateSpent"></v-switch>-->
        <!--                </v-col>-->
        <!--                <v-col cols="8">-->
        <!--                  <v-date-picker-->
        <!--                      v-model="dates"-->
        <!--                      :show-current="todayDate()"-->
        <!--                      reactive-->
        <!--                      first-day-of-week="1"-->
        <!--                      range-->
        <!--                  ></v-date-picker>-->
        <!--                  &lt;!&ndash;                  <v-spacer></v-spacer>&ndash;&gt;-->
        <!--                  <div class="text-right">-->
        <!--                    <v-btn-->
        <!--                        text-->
        <!--                        color="primary"-->
        <!--                        @click="menu = false"-->
        <!--                    >-->
        <!--                      Cancel-->
        <!--                    </v-btn>-->
        <!--                    <v-btn-->
        <!--                        text-->
        <!--                        color="primary"-->
        <!--                        @click="$refs.menu.save(dates);onclickOkPeekDate()"-->
        <!--                    >-->
        <!--                      OK-->
        <!--                    </v-btn>-->
        <!--                  </div>-->
        <!--                </v-col>-->
        <!--              </v-row>-->
        <!--            </v-card>-->

        <!--          </v-menu>-->
        <!--        </v-col>-->
        <!--        <v-col cols="12" sm="1" md="1">-->
        <!--          <v-select-->
        <!--              class="pt-3 mr-0"-->
        <!--              v-model="groupBy"-->
        <!--              label="Group By"-->
        <!--              :items="groupByItems"-->
        <!--              hide-details-->
        <!--          ></v-select>-->
        <!--        </v-col>-->
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              class="pt-1 mr-0"
              v-model="whoAdded"
              label="Who Added"
              :items="whoAddeds"
              hide-details
              dense
              chips
              multiple
              deletable-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              class="pt-1 mr-0"
              v-model="cab"
              :items="cabs"
              return-object
              label="Cab"
              item-text="name"
              item-value="id"
              hide-details
              dense
              chips
              multiple
              deletable-chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-select
              class="pt-3 mr-0"
              v-model="campaignType"
              label="Type"
              :items="campaignTypes"
              hide-details
              :menu-props='{maxHeight: 608}'
          >
            <template v-slot:item="{ item }">
              <v-icon class="pr-2">{{ item.icon }}</v-icon>
              {{ item.text }}
            </template>

            <template v-slot:selection="{ item }">
              <v-icon class="pr-2">{{ item.icon }}</v-icon>
              {{ item.text }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-text-field
              v-model="campaignId"
              hide-details
              label="Campaign Id"
              type="string"
              clearable
              @change="onChangeCampaignId"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="1" md="1">
          <v-text-field
              v-model="teaserId"
              hide-details
              label="Teaser Id"
              type="string"
              clearable
              @change="onChangeTeaserId"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="1" md="1">
          <v-combobox
              class="pt-1 mr-0"
              v-model="region"
              :items="allRegions"
              label="Region"
              chips
              dense
              multiple
              clearable
              hide-details
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              class="pt-4"
              dense
              v-model="offer"
              :items="allOffers"
              label="Offer"
              clearable
          ></v-autocomplete>
        </v-col>
        <!--        <v-col cols="12" sm="1" md="1">-->
        <!--          <v-text-field-->
        <!--              v-model="searchName"-->
        <!--              hide-details-->
        <!--              label="Name"-->
        <!--              type="string"-->
        <!--              clearable-->
        <!--          ></v-text-field>-->
        <!--        </v-col>-->


        <v-col cols="12" sm="1" md="1">
          <v-text-field
              v-model="searchTags"
              hide-details
              label="Tags"
              type="string"
              clearable
          ></v-text-field>
        </v-col>

        <!--        <v-col cols="12" sm="1" md="1">-->
        <!--          <v-combobox-->
        <!--              class="pt-1 mr-0"-->
        <!--              v-model="affNetworkSelected"-->
        <!--              :items="affNetworks"-->
        <!--              chips-->
        <!--              clearable-->
        <!--              label="AffNetworks"-->
        <!--              multiple-->
        <!--              hide-details-->
        <!--          >-->
        <!--            <template v-slot:selection="{ attrs, item, select, selected }">-->
        <!--              <v-chip-->
        <!--                  v-bind="attrs"-->
        <!--                  :input-value="selected"-->
        <!--                  close-->
        <!--                  small-->
        <!--                  @click="select"-->
        <!--                  @click:close="remove(item)"-->
        <!--              >-->
        <!--                <strong>{{ item }}</strong>&nbsp;-->
        <!--              </v-chip>-->
        <!--            </template>-->
        <!--          </v-combobox>-->
        <!--        </v-col>-->

        <v-col cols="12" sm="3">
          <v-pagination v-model="page" :length="pageCount" class="ml-5 mr-3 pt-3"></v-pagination>
        </v-col>

        <v-col cols="12" sm="1" md="1">
          <v-select
              dense
              class="pt-3 pl-3 mr-0"
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              hide-details
              solo
          ></v-select>
        </v-col>
      </v-row>
      <v-col cols="12" sm="12" md="12" class="pt-0 px-0">
        <v-data-table
            :headers="headers"
            :items="statistic"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :page.sync="page"
            :loading="loading"
            :server-items-length="total"
            :items-per-page="itemsPerPage"
            :expanded.sync="expanded"
            @page-count="pageCount = $event"
            @update:sort-desc="onUpdateSortTable"
            @item-expanded="itemExpanded"
            item-key="campaign_id"
            multi-sort
            hide-default-footer
            dense
            show-expand
        >
          <template v-slot:footer>
            <v-row
                v-if="itemsPerPage > 10"
                justify="start"
                align="start"
                width="100%"
                class="pl-3 pr-4 pt-3"
            >
              <v-row>
                <!--                <v-col cols="12" sm="5" offset-md="7" md="5">-->
                <v-col cols="12" sm="5" md="5">
                  <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </v-col>
              </v-row>
            </v-row>
          </template>

          <template v-slot:expanded-item="{ headers, item: campaign }">
            <td :colspan="headers.length" class="pb-2">
              <v-row
                  justify="start"
                  align="start"
              >
                <v-row class="mx-2 mt-2">
                  <v-col cols="12" sm="3" class="mt-1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            @click="refreshTeasers(campaign)"
                            x-small
                            :disabled="loading"
                            class="ml-3 " fab elevation="0" color="primary">
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>Reload this page</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            @click="refreshTeasers(campaign, true)"
                            x-small
                            :disabled="loading"
                            class="ml-3" fab elevation="0" color="light-green">
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>Refresh ALL teasers from MGID API</span>
                    </v-tooltip>
                    <now :lastUpdate="campaign.teasersData.lastUpdate" class="pl-2">

                    </now>
                    <span><B>{{ campaign.teasersData.total }}</B> teasers</span>

                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-pagination v-model="campaign.teasersData.page" :length="campaign.teasersData.pageCount"
                                  @input="refreshTeasers(campaign)"></v-pagination>
                  </v-col>
                  <v-col cols="12" sm="1">
                    <v-select
                        dense
                        class="pt-1 mr-0"
                        v-model="campaign.teasersData.limit"
                        :items="itemsPerPageOptions"
                        hide-details
                        solo
                        @change="refreshTeasers(campaign)"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-col cols="12" sm="12" no-gutters>
                  <v-data-table
                      class="pb-5"
                      :headers="headersTeasers"
                      item-key="id"
                      :items="campaign.teasersData.teasers"
                      :server-items-length="campaign.teasersData.total"
                      :loading="campaign.teasersData.loading"
                      :items-per-page="campaign.teasersData.limit"
                      :page.sync="campaign.teasersData.page"
                      @page-count="campaign.teasersData.pageCount = $event"
                      @update:page="refreshTeasers(campaign)"
                      hide-default-footer
                  >
                    <!--                    <template v-slot:body="{ items }">-->
                    <!--                      <tbody>-->
                    <!--                      <tr v-for="campaign in items" :key="campaign.name">-->
                    <!--                        <td class="col-40">1</td> &lt;!&ndash; 40% &ndash;&gt;-->
                    <!--                        <td class="col-10">2</td> &lt;!&ndash; 10% &ndash;&gt;-->
                    <!--                        <td class="col-50">3</td> &lt;!&ndash; 50% &ndash;&gt;-->
                    <!--                      </tr>-->
                    <!--                      </tbody>-->
                    <!--                    </template>-->


                    <template v-slot:header.imageLink="{}">
                      <v-row justify="center"
                             align="center"
                      >
                        <v-col cols="12" sm="1" offset="3" v-if="copyTeasersResult">
                          <v-btn color="green" @click="resetCopyTeasers(campaign)">
                            <v-icon>mdi-refresh</v-icon>
                            Reset
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="copyTeasersResult">
                          <router-link v-if="newTeasers.length > 0" @click.stop="()=>{}" :to="getNewTeasersLink()" target="_blank" class="title">New
                            Teasers in Wait
                          </router-link>
                          <router-link v-if="abTasksTeasers.length > 0" @click.stop="()=>{}" :to="getTeasersLink()" target="_blank" class="title">Tasks</router-link>
                        </v-col>
                        <v-col cols="12" sm="1" v-if="!copyTeasersResult">
                          <v-btn color="green"
                                 :disabled="!totalCopy(campaign.teasersData.teasers)>0 || copyTeasersLoading"
                                 :loading="copyTeasersLoading"
                                 @click="copyTeasers(campaign)">
                            <v-icon>mdi-content-save-all-outline</v-icon>
                            Copy {{ totalTeaserAndCopy(campaign.teasersData.teasers) }}
                          </v-btn>
                        </v-col>
                      </v-row>

                    </template>
                    <template v-slot:footer>
                      <v-row
                          justify="start"
                          align="start"
                          width="100%"
                          class="pl-3 pr-4 pt-3"
                      >
                        <v-row>
                          <v-col cols="12" sm="5" offset-md="7" md="5">
                            <v-pagination v-model="campaign.teasersData.page" :length="campaign.teasersData.pageCount"
                                          @input="refreshTeasers(campaign)"></v-pagination>
                          </v-col>
                        </v-row>
                      </v-row>
                    </template>

                    <template v-slot:item.id="{ item: itemTeaser }">
                      <v-row no-gutters>
                        <v-col cols="12" sm="12" class="pa-0">
                          <!--                          <v-row class="pl-0" v-if="itemTeaser['$type'] === 'teaserForCopy'" justify="center"-->
                          <!--                                 align="center">-->
                          <!--                            <v-col cols="12" sm="4" class="pl-0 ml-0 pr-0">-->
                          <!--                              {{ itemTeaser }}-->
                          <!--                            </v-col>-->
                          <!--                          </v-row>-->
                          <v-row class="pl-0" v-if="itemTeaser['$type'] === 'teaser'" justify="center" align="center">
                            <v-col cols="12" sm="4" class="pl-0 ml-0 pr-0">
                              <div v-if="itemTeaser.videoOptions" :style="{width: '100%'}">
                                <video-player :options="itemTeaser.videoOptions"
                                              :style="{width: '100%'}"></video-player>
                              </div>
                              <v-img v-else :src="itemTeaser.imageLink" :style="{width: '330px'}"></v-img>
                            </v-col>
                            <v-col cols="12" sm="8" class="">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-hover v-slot:default="{ hover }">
                                <span v-on="on" @click.stop="copyToClipboard(itemTeaser.id)"
                                      :style="{'cursor': hover ? 'pointer': 'default'}" class="subtitle-2 pr-3">{{
                                    itemTeaser.id
                                  }}</span>
                                  </v-hover>
                                </template>
                                <span>click for copy</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="itemTeaser.status === 'active'">
                                <template v-slot:activator="{ on }">
                                  <v-hover v-slot:default="{ hover }">
                                    <!--                                <span v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}" class="subtitle-2">{{ itemTeaser.status }}</span>-->
                                    <v-icon v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}"
                                            color="light-green">
                                      mdi-circle
                                    </v-icon>
                                  </v-hover>
                                </template>
                                <span>active</span>
                              </v-tooltip>

                              <v-tooltip bottom v-if="itemTeaser.status === 'blocked'">
                                <template v-slot:activator="{ on }">
                                  <v-hover v-slot:default="{ hover }">
                                    <!--                                <span v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}" class="subtitle-2">{{ itemTeaser.status }}</span>-->
                                    <v-icon v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}"
                                            color="red--text">
                                      mdi-stop-circle-outline
                                    </v-icon>
                                  </v-hover>
                                </template>
                                <span>blocked</span>
                              </v-tooltip>

                              <v-tooltip bottom v-if="itemTeaser.status === 'campaignBlocked'">
                                <template v-slot:activator="{ on }">
                                  <v-hover v-slot:default="{ hover }">
                                    <!--                                <span v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}" class="subtitle-2">{{ itemTeaser.status }}</span>-->
                                    <v-icon v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}"
                                            class="red--text">
                                      mdi-pause
                                    </v-icon>
                                  </v-hover>
                                </template>
                                <span>campaignBlocked</span>
                              </v-tooltip>

                              <v-tooltip bottom v-if="itemTeaser.status === 'onModeration'">
                                <template v-slot:activator="{ on }">
                                  <v-hover v-slot:default="{ hover }">
                                    <!--                                <span v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}" class="subtitle-2">{{ itemTeaser.status }}</span>-->
                                    <v-icon v-on="on" :style="{'c': hover ? 'pointer': 'default'}"
                                            class="red--text">
                                      mdi-account-tie-hat-outline
                                    </v-icon>
                                  </v-hover>
                                </template>
                                <span>onModeration</span>
                              </v-tooltip>

                              <v-tooltip bottom v-if="itemTeaser.status === 'rejected'">
                                <template v-slot:activator="{ on }">
                                  <v-hover v-slot:default="{ hover }">
                                    <!--                                <span v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}" class="subtitle-2">{{ itemTeaser.status }}</span>-->
                                    <v-icon v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}"
                                            class="red--text">
                                      mdi-cow-off
                                    </v-icon>
                                  </v-hover>
                                </template>
                                <span>rejected</span>
                              </v-tooltip>

                              <v-tooltip bottom v-if="itemTeaser.status === 'rejected_by_client'">
                                <template v-slot:activator="{ on }">
                                  <v-hover v-slot:default="{ hover }">
                                    <!--                                <span v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}" class="subtitle-2">{{ itemTeaser.status }}</span>-->
                                    <v-icon v-on="on" :style="{'cursor': hover ? 'pointer': 'default'}"
                                            class="red--text">
                                      mdi-csim-off-outline
                                    </v-icon>
                                  </v-hover>
                                </template>
                                <span>rejected_by_client</span>
                              </v-tooltip>

                              <span class="subtitle-2 pl-4">{{ itemTeaser.categoryName }}</span> / <span
                                class="subtitle-2">{{
                                itemTeaser.categoryIab_code
                              }}</span> / <span class="subtitle-2">{{ itemTeaser.ad_type }}</span>
                              <a :href="itemTeaser.url" target="_blank"><span class="subtitle- pl-4">{{
                                  itemTeaser.url
                                }}</span></a>
                              <br>
                              <v-hover v-slot:default="{ hover }">
                              <span @click.stop="copyToClipboard(itemTeaser.title)"
                                    :style="{'cursor': hover ? 'pointer': 'default'}" class="subtitle-2">{{
                                  itemTeaser.title
                                }}</span>
                              </v-hover>
                              <br>
                              <v-hover v-slot:default="{ hover }">
                              <span @click.stop="copyToClipboard(itemTeaser.advertText)"
                                    :style="{'cursor': hover ? 'pointer': 'default'}" class="subtitle-2">{{
                                  itemTeaser.advertText
                                }}</span>
                              </v-hover>
                              <br>
                              Price: <span>{{ itemTeaser.goodPrice }}</span> / <span><s>{{
                                itemTeaser.goodOldPrice
                              }}</s></span>
                              <span class="subtitle-2 pl-4">{{ itemTeaser.currencyName }}</span> / <span
                                class="subtitle-2">{{
                                itemTeaser.currencyNameUTF
                              }}</span>
                              <br>
                              Tags:
                              <div v-for="(tags, tagName) in itemTeaser.tags" :key="tagName" class="pl-9">
                                <span>{{ tagName }}: {{ tags.join(', ') }}</span><br>
                              </div>
                              <br>
                              <span v-if="itemTeaser.statusReason_if_drop_karantin.length > 0">Message:</span>
                              <div v-for="Reason in itemTeaser.statusReason_if_drop_karantin" :key="Reason"
                                   class="pl-9">
                                <span class="subtitle-2 pl-4">{{ Reason }}</span><br>
                              </div>
                              <br>

                            </v-col>
                          </v-row>
                          <!--                        </v-container>-->
                          <!--                      </v-card>-->
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item.url="{ item: itemTeaser, index }">
                      <v-row no-gutters>
                        <v-col>
                          <v-btn icon @click="insertCopyTeaser(campaign.teasersData, itemTeaser, index)"
                                 :disabled="copyTeasersResult || copyTeasersLoading || isVisibleCopyTeaser(itemTeaser.status)"
                                 color="green darken-1">
                            <v-icon>mdi-file-document-plus-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="itemTeaser['$type'] === 'teaserForCopy'">
                        <v-col>
                          <v-btn icon @click="deleteCopyTeaser(campaign.teasersData, itemTeaser, index)"
                                 :disabled="copyTeasersResult || copyTeasersLoading"
                                 color="red accent-2">
                            <v-icon>mdi-file-document-minus-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item.imageLink="{ item: itemTeaser }">
                      <v-tabs vertical v-model="itemTeaser.action">
                        <v-tab href="#copy">
                          <v-icon left>
                            mdi-content-copy
                          </v-icon>
                        </v-tab>
                        <v-tab href="#taskToA">
                          <v-icon left>
                            mdi-alpha-a-circle-outline
                          </v-icon>
                        </v-tab>
                        <v-tab href="#taskToB">
                          <v-icon left>
                            mdi-alpha-b-circle-outline
                          </v-icon>
                        </v-tab>
                        <v-tab-item value="copy">
                          <v-form v-if="itemTeaser.action === 'copy' && itemTeaser['$type'] === 'teaser' "
                                  v-model="itemTeaser.teasersForCopy[0].isValid"
                                  :disabled="copyTeasersResult || copyTeasersLoading || isVisibleCopyTeaser(itemTeaser.status)">
                            <v-row no-gutters class="pt-3 p">
                              <v-col cols="12" sm="2" class="pr-2">
                                <v-select
                                    light
                                    hide-details
                                    dense
                                    flat
                                    label="Dep"
                                    :items="dspDepsAndTeams"
                                    return-object
                                    :disabled="itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create"
                                    v-model="itemTeaser.teasersForCopy[0].dep"
                                    :rules="[validateDep]">
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="3" class="pr-2">
                                <v-autocomplete
                                    :items="itemTeaser.teasersForCopy[0].dep !== null ? itemTeaser.teasersForCopy[0].dep.value : []"
                                    return-object
                                    label="Team"
                                    dense
                                    v-model="itemTeaser.teasersForCopy[0].team"
                                    :disabled="(itemTeaser.teasersForCopy[0].dep === null) || itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create"
                                    @click:clear="team = null; owner= null"
                                    :rules="[validateTeam]"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-autocomplete label="Owner"
                                                dense
                                                v-model="itemTeaser.teasersForCopy[0].owner"
                                                :items="itemTeaser.teasersForCopy[0].team !== null ? itemTeaser.teasersForCopy[0].team.value : []"
                                                :disabled="(itemTeaser.teasersForCopy[0].team === null || itemTeaser.teasersForCopy[0].team.value.length === 0) || itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create"
                                                :rules="[validateOwner]"

                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="3" class="pr-2">
                                <v-autocomplete
                                    label="Country"
                                    dense
                                    v-model="itemTeaser.teasersForCopy[0].country"
                                    :items="countries"
                                    auto-select-first
                                    :rules="[validateCountry]"
                                    :disabled="(itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create) || itemTeaser.teasersForCopy[0].isValidFullOfferName"
                                >
                                  <template v-slot:prepend>
                                    <flag :iso="itemTeaser.teasersForCopy[0].country"/>
                                  </template>

                                  <template v-slot:item="{ item: country }">
                                    <flag :iso="country.value" class="mr-2"/>
                                    {{ country.text }}
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-text-field
                                    label="Payout"
                                    type="string"
                                    prepend-icon="mdi-cash"
                                    dense
                                    v-model="itemTeaser.teasersForCopy[0].payout"
                                    :rules="[validatePayout]"
                                    :disabled="itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create"
                                >
                                  <template v-slot:append-outer>
                                    $
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                    label="URL"
                                    type="string"
                                    v-model="itemTeaser.teasersForCopy[0].url"
                                    :rules="[validateURL]"
                                    :disabled="itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create"
                                    @input="setURL($event)"
                                >
                                  <template v-slot:append-outer>
                                    <v-btn icon :href="itemTeaser.teasersForCopy[0].url" target="_blank"
                                           :disabled="itemTeaser.teasersForCopy[0].url === null || itemTeaser.teasersForCopy[0].url.length === 0">
                                      <v-icon>mdi-open-in-new</v-icon>
                                    </v-btn>
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="5" class="pl-1">
                                <v-text-field
                                    class="pr-2"
                                    hide-details
                                    label="Offer"
                                    type="string"
                                    v-model="itemTeaser.teasersForCopy[0].offer"
                                    :rules="[validateOffer]"
                                    :disabled="(itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create) || itemTeaser.teasersForCopy[0].isValidFullOfferName"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field
                                    hide-details
                                    label="offer_id"
                                    type="string"
                                    v-model="itemTeaser.teasersForCopy[0].offer_id"
                                    :rules="[validateOfferId]"
                                    :disabled="(itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create) || itemTeaser.teasersForCopy[0].isValidFullOfferName"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="1" align="center">
                                <v-checkbox :disabled="itemTeaser.teasersForCopy[0].create"
                                            v-show="itemTeaser.teasersForCopy[0].isValidFullOfferName"
                                            v-model="itemTeaser.teasersForCopy[0].isValidFullOfferName"
                                            :rules="[() => itemTeaser.teasersForCopy[0].isValidFullOfferName]"
                                            class="pa-0 ma-0 pt-5 pl-6" hide-details></v-checkbox>
                                <v-tooltip bottom v-if="!itemTeaser.teasersForCopy[0].isValidFullOfferName">
                                  <template v-slot:activator="{ on,attrs }">
                                    <v-btn v-on="on" v-bind="attrs" icon class="mt-3" color="primary"
                                           :disabled="(validateOfferId(itemTeaser.teasersForCopy[0].offer_id) !== true || validateOffer(itemTeaser.teasersForCopy[0].offer) !== true || validateCountry(itemTeaser.teasersForCopy[0].country) !== true)"
                                           @click="validateFullOfferName(itemTeaser.teasersForCopy[0])">
                                      <v-icon>mdi-send-check</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Validate offer name</span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="12" sm="3" class="pl-1">
                                <v-autocomplete
                                    hide-details
                                    label="pp"
                                    :items="pps"
                                    type="string"
                                    v-model="itemTeaser.teasersForCopy[0].pp"
                                    :rules="[validatePp]"
                                    :disabled="(itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create)"
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="12" align="center">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon class="mt-3"
                                           :color="itemTeaser.teasersForCopy[0].create ? 'green' : ''"
                                           :disabled="!itemTeaser.teasersForCopy[0].isValid || copyTeasersResult || copyTeasersLoading"
                                           @click="itemTeaser.teasersForCopy[0].create = !itemTeaser.teasersForCopy[0].create">
                                      <v-icon>mdi-check-circle</v-icon>
                                    </v-btn>
                                  </template>
                                  <div>
                                    <span v-if="!itemTeaser.teasersForCopy[0].isValid">Please correct</span>
                                    <span
                                        v-if="itemTeaser.teasersForCopy[0].isValid && !itemTeaser.teasersForCopy[0].create">Ready for create, please confirm</span>
                                    <span
                                        v-if="itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create">All good, ready for create</span>
                                  </div>
                                </v-tooltip>

                                <router-link v-if="copyTeasersResult && itemTeaser.teasersForCopy[0].newTeaserId"
                                             @click.stop="()=>{}"
                                             :to="getNewTeaserLink(itemTeaser.teasersForCopy[0].newTeaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.teasersForCopy[0].newTeaserId }}
                                </router-link>
                                <span v-if="copyTeasersResult && itemTeaser.teasersForCopy[0].newTeaserError"
                                      class="title red--text text--lighten-1">{{
                                    itemTeaser.teasersForCopy[0].newTeaserError
                                  }}</span>
                              </v-col>

                            </v-row>
                          </v-form>
                          <v-form v-else-if="itemTeaser.action === 'copy' && itemTeaser['$type'] === 'teaserForCopy'"
                                  v-model="itemTeaser.isValid" :disabled="copyTeasersResult || copyTeasersLoading">
                            <v-row no-gutters class="pt-3">
                              <v-col cols="12" sm="2" class="pr-2">
                                <v-select
                                    light
                                    hide-details
                                    dense
                                    flat
                                    label="Dep"
                                    :items="dspDepsAndTeams"
                                    return-object
                                    v-model="itemTeaser.dep"
                                    :disabled="itemTeaser.isValid && itemTeaser.create"
                                    :rules="[validateDep]">
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="3" class="pr-2">
                                <v-autocomplete
                                    :items="itemTeaser.dep !== null ? itemTeaser.dep.value : []"
                                    return-object
                                    label="Team"
                                    dense
                                    v-model="itemTeaser.team"
                                    :disabled="(itemTeaser.dep === null) || (itemTeaser.isValid && itemTeaser.create)"
                                    @click:clear="team = null; owner= null"
                                    :rules="[validateTeam]"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="2">
                                <v-autocomplete label="Owner"
                                                dense
                                                v-model="itemTeaser.owner"
                                                :items="itemTeaser.team !== null ? itemTeaser.team.value : []"
                                                :disabled="(itemTeaser.team === null || itemTeaser.team.value.length === 0) || (itemTeaser.isValid && itemTeaser.create)"
                                                :rules="[validateOwner]"
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="2" class="pr-2">
                                <v-autocomplete
                                    label="Country"
                                    dense
                                    v-model="itemTeaser.country"
                                    :items="countries"
                                    auto-select-first
                                    :rules="[validateCountry]"
                                    :disabled="(itemTeaser.isValid && itemTeaser.create) || itemTeaser.isValidFullOfferName"
                                >
                                  <template v-slot:prepend>
                                    <flag :iso="itemTeaser.country"/>
                                  </template>

                                  <template v-slot:item="{ item: country }">
                                    <flag :iso="country.value"/>
                                    {{ country.text }}
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field
                                    label="Payout"
                                    type="string"
                                    prepend-icon="mdi-cash"
                                    dense
                                    v-model="itemTeaser.payout"
                                    :rules="[validatePayout]"
                                    :disabled="itemTeaser.isValid && itemTeaser.create"
                                >
                                  <template v-slot:append-outer>
                                    $
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                    hide-details
                                    label="URL"
                                    type="string"
                                    v-model="itemTeaser.url"
                                    :rules="[validateURL]"
                                    :disabled="itemTeaser.isValid && itemTeaser.create"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="5" class="pl-1">
                                <v-text-field
                                    class="pr-2"
                                    hide-details
                                    label="Offer"
                                    type="string"
                                    :rules="[validateOffer]"
                                    v-model="itemTeaser.offer"
                                    :disabled="(itemTeaser.isValid && itemTeaser.create) || itemTeaser.isValidFullOfferName"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field
                                    hide-details
                                    label="offer_id"
                                    type="string"
                                    v-model="itemTeaser.offer_id"
                                    :rules="[validateOfferId]"
                                    :disabled="(itemTeaser.isValid && itemTeaser.create) || itemTeaser.isValidFullOfferName"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="1" align="center">
                                <v-checkbox :disabled="itemTeaser.create" v-show="itemTeaser.isValidFullOfferName"
                                            v-model="itemTeaser.isValidFullOfferName"
                                            :rules="[() => itemTeaser.isValidFullOfferName]" class="pa-0 ma-0 pt-5 pl-6"
                                            hide-details></v-checkbox>
                                <v-tooltip bottom v-if="!itemTeaser.isValidFullOfferName">
                                  <template v-slot:activator="{ on,attrs }">
                                    <v-btn v-on="on" v-bind="attrs" icon class="mt-3" color="primary"
                                           :disabled="(validateOfferId(itemTeaser.offer_id) !== true || validateOffer(itemTeaser.offer) !== true || validateCountry(itemTeaser.country) !== true)"
                                           @click="validateFullOfferName(itemTeaser)">
                                      <v-icon>mdi-send-check</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Validate offer name</span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="12" sm="3" class="pl-1">
                                <v-autocomplete
                                    hide-details
                                    label="pp"
                                    :items="pps"
                                    type="string"
                                    v-model="itemTeaser.pp"
                                    :rules="[validatePp]"
                                    :disabled="(itemTeaser.isValid && itemTeaser.create)"
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="12" align="center">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon class="mt-3" :color="itemTeaser.create ? 'green' : ''"
                                           :disabled="!itemTeaser.isValid || copyTeasersResult || copyTeasersLoading"
                                           @click="itemTeaser.create = !itemTeaser.create">
                                      <v-icon>mdi-check-circle</v-icon>
                                    </v-btn>
                                  </template>
                                  <div>
                                    <span v-if="!itemTeaser.isValid">Please correct</span>
                                    <span
                                        v-if="itemTeaser.isValid && !itemTeaser.create">Ready for create, please confirm</span>
                                    <span
                                        v-if="itemTeaser.isValid && itemTeaser.create">All good, ready for create</span>
                                  </div>
                                </v-tooltip>
                                <router-link v-if="copyTeasersResult && itemTeaser.newTeaserId"
                                             @click.stop="()=>{}"
                                             :to="getNewTeaserLink(itemTeaser.newTeaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.newTeaserId }}
                                </router-link>
                                <span v-if="copyTeasersResult && itemTeaser.newTeaserError"
                                      class="title red--text text--lighten-1">{{ itemTeaser.newTeaserError }}</span>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-tab-item>
                        <v-tab-item value="taskToA">
                          <v-form v-if="itemTeaser.action === 'taskToA' && itemTeaser['$type'] === 'teaser' "
                                  v-model="itemTeaser.teasersForCopy[0].isValid"
                                  :disabled="copyTeasersResult || copyTeasersLoading || isVisibleCopyTeaser(itemTeaser.status)">
                            <v-row no-gutters class="pt-3 p">
                              <v-col cols="12" sm="3" class="pr-2">
                                <v-text-field
                                    hide-details
                                    label="DSP Teaser ID"
                                    type="string"
                                    v-model="itemTeaser.teasersForCopy[0].teaserId"
                                    :rules="[validateTeaserId]"
                                    :disabled="itemTeaser.teasersForCopy[0].create"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" align="center">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon class="mt-3"
                                           :color="itemTeaser.teasersForCopy[0].create ? 'green' : ''"
                                           :disabled="!itemTeaser.teasersForCopy[0].isValid || copyTeasersResult || copyTeasersLoading"
                                           @click="itemTeaser.teasersForCopy[0].create = !itemTeaser.teasersForCopy[0].create">
                                      <v-icon>mdi-check-circle</v-icon>
                                    </v-btn>
                                  </template>
                                  <div>
                                    <span v-if="!itemTeaser.teasersForCopy[0].isValid">Please correct</span>
                                    <span
                                        v-if="itemTeaser.teasersForCopy[0].isValid && !itemTeaser.teasersForCopy[0].create">Ready for create, please confirm</span>
                                    <span
                                        v-if="itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create">All good, ready for create</span>
                                  </div>
                                </v-tooltip>

                                <router-link v-if="copyTeasersResult && itemTeaser.teasersForCopy[0].taskId"
                                             @click.stop="()=>{}"
                                             :to="getTeaserLink(itemTeaser.teasersForCopy[0].teaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.teasersForCopy[0].teaserId }}
                                </router-link>
                                <router-link v-if="copyTeasersResult && itemTeaser.teasersForCopy[0].newTeaserId"
                                             @click.stop="()=>{}"
                                             :to="getNewTeaserLink(itemTeaser.teasersForCopy[0].newTeaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.teasersForCopy[0].newTeaserId }}
                                </router-link>
                                <span v-if="copyTeasersResult && itemTeaser.teasersForCopy[0].newTeaserError"
                                      class="title red--text text--lighten-1">{{
                                    itemTeaser.teasersForCopy[0].newTeaserError
                                  }}</span>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-form v-else-if="itemTeaser.action === 'taskToA' && itemTeaser['$type'] === 'teaserForCopy'"
                                  v-model="itemTeaser.isValid" :disabled="copyTeasersResult || copyTeasersLoading">
                            <v-row no-gutters class="pt-3">
                              <v-col cols="12" sm="3" class="pr-2">
                                <v-text-field
                                    hide-details
                                    label="DSP Teaser ID"
                                    type="string"
                                    v-model="itemTeaser.teaserId"
                                    :rules="[validateTeaserId]"
                                    :disabled="itemTeaser.create"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" align="center">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon class="mt-3" :color="itemTeaser.create ? 'green' : ''"
                                           :disabled="!itemTeaser.isValid || copyTeasersResult || copyTeasersLoading"
                                           @click="itemTeaser.create = !itemTeaser.create">
                                      <v-icon>mdi-check-circle</v-icon>
                                    </v-btn>
                                  </template>
                                  <div>
                                    <span v-if="!itemTeaser.isValid">Please correct</span>
                                    <span
                                        v-if="itemTeaser.isValid && !itemTeaser.create">Ready for create, please confirm</span>
                                    <span
                                        v-if="itemTeaser.isValid && itemTeaser.create">All good, ready for create</span>
                                  </div>
                                </v-tooltip>
                                <router-link v-if="copyTeasersResult && itemTeaser.taskId"
                                             @click.stop="()=>{}"
                                             :to="getTeaserLink(itemTeaser.teaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.teaserId }}
                                </router-link>
                                <router-link v-if="copyTeasersResult && itemTeaser.newTeaserId"
                                             @click.stop="()=>{}"
                                             :to="getNewTeaserLink(itemTeaser.newTeaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.newTeaserId }}
                                </router-link>
                                <span v-if="copyTeasersResult && itemTeaser.newTeaserError"
                                      class="title red--text text--lighten-1">{{ itemTeaser.newTeaserError }}</span>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-tab-item>
                        <v-tab-item value="taskToB">
                          <v-form v-if="itemTeaser.action === 'taskToB' && itemTeaser['$type'] === 'teaser' "
                                  v-model="itemTeaser.teasersForCopy[0].isValid"
                                  :disabled="copyTeasersResult || copyTeasersLoading || isVisibleCopyTeaser(itemTeaser.status)">
                            <v-row no-gutters class="pt-3 p">
                              <v-col cols="12" sm="3" class="pr-2">
                                <v-text-field
                                    hide-details
                                    label="DSP Teaser ID"
                                    type="string"
                                    v-model="itemTeaser.teasersForCopy[0].teaserId"
                                    :rules="[validateTeaserId]"
                                    :disabled="itemTeaser.teasersForCopy[0].create"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" align="center">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon class="mt-3"
                                           :color="itemTeaser.teasersForCopy[0].create ? 'green' : ''"
                                           :disabled="!itemTeaser.teasersForCopy[0].isValid || copyTeasersResult || copyTeasersLoading"
                                           @click="itemTeaser.teasersForCopy[0].create = !itemTeaser.teasersForCopy[0].create">
                                      <v-icon>mdi-check-circle</v-icon>
                                    </v-btn>
                                  </template>
                                  <div>
                                    <span v-if="!itemTeaser.teasersForCopy[0].isValid">Please correct</span>
                                    <span
                                        v-if="itemTeaser.teasersForCopy[0].isValid && !itemTeaser.teasersForCopy[0].create">Ready for create, please confirm</span>
                                    <span
                                        v-if="itemTeaser.teasersForCopy[0].isValid && itemTeaser.teasersForCopy[0].create">All good, ready for create</span>
                                  </div>
                                </v-tooltip>

                                <router-link v-if="copyTeasersResult && itemTeaser.teasersForCopy[0].taskId"
                                             @click.stop="()=>{}"
                                             :to="getTeaserLink(itemTeaser.teasersForCopy[0].teaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.teasersForCopy[0].teaserId }}
                                </router-link>
                                <router-link v-if="copyTeasersResult && itemTeaser.teasersForCopy[0].newTeaserId"
                                             @click.stop="()=>{}"
                                             :to="getNewTeaserLink(itemTeaser.teasersForCopy[0].newTeaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.teasersForCopy[0].newTeaserId }}
                                </router-link>
                                <span v-if="copyTeasersResult && itemTeaser.teasersForCopy[0].newTeaserError"
                                      class="title red--text text--lighten-1">{{
                                    itemTeaser.teasersForCopy[0].newTeaserError
                                  }}</span>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-form v-else-if="itemTeaser.action === 'taskToB' && itemTeaser['$type'] === 'teaserForCopy'"
                                  v-model="itemTeaser.isValid" :disabled="copyTeasersResult || copyTeasersLoading">
                            <v-row no-gutters class="pt-3">
                              <v-col cols="12" sm="3" class="pr-2">
                                <v-text-field
                                    hide-details
                                    label="DSP Teaser ID"
                                    type="string"
                                    v-model="itemTeaser.teaserId"
                                    :rules="[validateTeaserId]"
                                    :disabled="itemTeaser.create"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" align="center">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon class="mt-3" :color="itemTeaser.create ? 'green' : ''"
                                           :disabled="!itemTeaser.isValid || copyTeasersResult || copyTeasersLoading"
                                           @click="itemTeaser.create = !itemTeaser.create">
                                      <v-icon>mdi-check-circle</v-icon>
                                    </v-btn>
                                  </template>
                                  <div>
                                    <span v-if="!itemTeaser.isValid">Please correct</span>
                                    <span
                                        v-if="itemTeaser.isValid && !itemTeaser.create">Ready for create, please confirm</span>
                                    <span
                                        v-if="itemTeaser.isValid && itemTeaser.create">All good, ready for create</span>
                                  </div>
                                </v-tooltip>
                                <router-link v-if="copyTeasersResult && itemTeaser.taskId"
                                             @click.stop="()=>{}"
                                             :to="getTeaserLink(itemTeaser.teaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.teaserId }}
                                </router-link>
                                <router-link v-if="copyTeasersResult && itemTeaser.newTeaserId"
                                             @click.stop="()=>{}"
                                             :to="getNewTeaserLink(itemTeaser.newTeaserId)"
                                             target="_blank" class="title">
                                  {{ itemTeaser.newTeaserId }}
                                </router-link>
                                <span v-if="copyTeasersResult && itemTeaser.newTeaserError"
                                      class="title red--text text--lighten-1">{{ itemTeaser.newTeaserError }}</span>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-tab-item>
                      </v-tabs>


                    </template>
                  </v-data-table>
                </v-col>
              </v-row>


            </td>
          </template>

          <template slot="body.prepend">
            <tr class="primary--text">
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy === 'Campaign'"></th>
              <th v-if="groupBy !== 'Date'"></th>
              <th v-if="affNetworkSelected.length>0"></th>
              <th>{{ totalCampaigns }}</th>
              <!--              <th>{{ totalCpc }}</th>-->
              <!--              <th>{{ totalSpent }}</th>-->
              <th v-if="affNetworkSelected.length>0"> {{ totalRevenue }}</th>
              <th v-if="affNetworkSelected.length>0">{{ totalProfit }}</th>
              <th v-if="affNetworkSelected.length>0">{{ totalRoi }} %</th>
              <!--              <th>{{ totalSale }}</th>-->
              <!--              <th>{{ totalClicks }}</th>-->
            </tr>
          </template>

          <template v-slot:item.cabName="{ item }">
            <LastUpdateToolTip :last-update="item.cabLastUpdate" :timeoutLastUpdate="30"
                               :text="item.cabName"></LastUpdateToolTip>
          </template>

          <template v-if="affNetworkSelected.length>0" v-slot:item.profit="{ item }">
            <div v-if="item.profit < 0 " class="red--text">
              {{ item.profit }}
            </div>
            <div v-if="item.profit > 0">
              {{ item.profit }}
            </div>
          </template>

          <template v-if="affNetworkSelected.length>0" v-slot:item.roi="{ item }">
            <div v-if="item.roi < 0 " class="red--text">
              {{ item.roi }} %
            </div>
            <div v-else-if="item.roi > 0" class="green--text">
              {{ item.roi }} %
            </div>
            <div v-else>
              {{ item.roi }} %
            </div>
          </template>

          <template v-slot:item.campaign_id="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span>
                  <v-icon v-if="item.categoryType === 'dsp'" v-on="on" class="mr-1">mdi-alpha-d-circle-outline</v-icon>
                  <v-icon v-if="item.categoryType === 'content'" v-on="on" class="mr-1">mdi-alpha-c-circle-outline
                  </v-icon>
                  <v-icon v-if="item.categoryType === 'product'" v-on="on" class="mr-1">mdi-cart-outline</v-icon>
                  <v-icon v-if="item.categoryType === 'push'" v-on="on" class="mr-1">mdi-message-badge-outline</v-icon>
                  <v-icon v-if="item.categoryType === 'search_feed'" v-on="on" class="mr-1">mdi-magnify</v-icon>
                  <v-icon v-if="item.categoryType === 'video'" v-on="on" class="mr-1">mdi-video</v-icon>
                </span>

              </template>
              <span>
                <span v-if="item.categoryType === 'dsp'">DSP</span>
                  <span v-if="item.categoryType === 'content'">Content</span>
                  <span v-if="item.categoryType === 'product'">Product</span>
                  <span v-if="item.categoryType === 'push'">Push</span>
                  <span v-if="item.categoryType === 'search_feed'">Search feed</span>
                  <span v-if="item.categoryType === 'video'">Video</span>
              </span>
            </v-tooltip>

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>Content</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>Product</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>push</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>Search feed</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom >-->
            <!--              <template v-slot:activator="{ on }">-->
            <!--                -->
            <!--              </template>-->
            <!--              <span>Video</span>-->
            <!--            </v-tooltip>-->


            <!--            <StartStopMenu :key="item.campaign_id" :item="item" @update="getDataFromApi()"></StartStopMenu>-->

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span>
                  <v-icon v-if="item.status_name === 'active'" v-on="on" color="green">mdi-play-circle</v-icon>
                  <v-icon v-if="item.status_name === 'paused'" v-on="on" color="red">mdi-pause</v-icon>
                </span>
              </template>
              <span>
               <span v-if="item.status_name === 'active'">active</span>
               <span v-if="item.status_name === 'paused'">{{ item.status_description }}</span>
              </span>
            </v-tooltip>


            <!--            <v-menu v-if="item.status_name === 'active'" min-width="500px" offset-y :close-on-content-click="false">-->
            <!--              <template v-slot:activator="{ on: onMenu, attrs: attrsMenu}" >-->
            <!--                <v-tooltip bottom>-->
            <!--                  <template v-slot:activator="{ on, attrs }">-->
            <!--                    <v-hover v-slot="{ hover }">-->
            <!--                      <v-btn icon-->
            <!--                             v-bind="attrs"-->
            <!--                             v-on="{on, ...onMenu, ...attrsMenu}"-->
            <!--                      >-->
            <!--                        <v-icon v-if="!hover" color="green">mdi-play-circle</v-icon>-->
            <!--                        <v-icon v-if="hover" color="red">mdi-pause</v-icon>-->
            <!--                      </v-btn>-->
            <!--                    </v-hover>-->
            <!--                  </template>-->
            <!--                  <span>Stop</span>-->
            <!--                </v-tooltip>-->
            <!--              </template>-->

            <!--              <v-card :close-on-content-click="false">-->
            <!--                <v-card-title class="red">-->
            <!--                  <span class="headline">Stop campaign</span>-->
            <!--                </v-card-title>-->
            <!--                <v-card-text>-->
            <!--                  <v-container>-->
            <!--                    <v-row>-->
            <!--                      <v-col cols="12" sm="12" md="12" >-->
            <!--                        <span class="headline"><b>{{ item.campaign_id }}</b> :  {{ item.name }}</span>-->
            <!--                      </v-col>-->
            <!--                    </v-row>-->
            <!--                  </v-container>-->
            <!--                </v-card-text>-->

            <!--                <v-card-actions>-->
            <!--                  <v-spacer></v-spacer>-->
            <!--                  <v-btn color="primary" @click="toStartCampaign = null">-->
            <!--                    Yes-->
            <!--                  </v-btn>-->
            <!--                </v-card-actions>-->

            <!--              </v-card>-->

            <!--            </v-menu>-->

            <!--            <v-tooltip bottom v-if="item.status_name === 'active'">-->
            <!--              <template v-slot:activator="{ on, attrs }">-->
            <!--                <v-hover v-slot="{ hover }">-->
            <!--                  <v-btn icon-->
            <!--                         v-bind="attrs"-->
            <!--                         v-on="on"-->
            <!--                         @click.stop="toStartCampaign = item; confirmStartStopCampaign =true"-->
            <!--                  >-->
            <!--                    <v-icon v-if="!hover" color="green">mdi-play-circle</v-icon>-->
            <!--                    <v-icon v-if="hover" color="red">mdi-pause</v-icon>-->
            <!--                  </v-btn>-->
            <!--                </v-hover>-->
            <!--              </template>-->
            <!--              <span>Stop</span>-->
            <!--            </v-tooltip>-->

            <!--            <v-tooltip bottom v-if="item.status_name === 'paused'">-->
            <!--              <template v-slot:activator="{ on, attrs }">-->
            <!--                <v-hover v-slot="{ hover }">-->
            <!--                  <v-btn icon-->
            <!--                         v-bind="attrs"-->
            <!--                         v-on="on"-->
            <!--                         @click.stop="toStartCampaign = item; confirmStartStopCampaign =true"-->
            <!--                  >-->
            <!--                    <v-icon v-if="hover" color="green">mdi-play-circle</v-icon>-->
            <!--                    <v-icon v-if="!hover" color="red">mdi-pause</v-icon>-->
            <!--                  </v-btn>-->
            <!--                </v-hover>-->
            <!--              </template>-->
            <!--              <span>Start </span>-->
            <!--            </v-tooltip>-->

            <v-tooltip bottom v-if="item.status_name === 'active'">
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <span v-on="on" @click.stop="copyToClipboard(item.campaign_id)"
                        :style="{'cursor': hover ? 'pointer': 'default'}">{{ item.campaign_id }}</span>
                </v-hover>
              </template>
              <span>{{ item.status_name }}, click for copy</span>
            </v-tooltip>

            <v-tooltip bottom v-else-if="item.status_name">
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <span v-on="on" @click.stop="copyToClipboard(item.campaign_id)"
                        :style="{'cursor': hover ? 'pointer': 'default'}">{{ item.campaign_id }}</span>
                </v-hover>

              </template>
              <span>{{ item.status_name }}, click for copy</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <span v-on="on" class="red lighten-2" @click.stop="copyToClipboard(item.campaign_id)"
                        :style="{'cursor': hover ? 'pointer': 'default'}">{{ item.campaign_id }}</span>
                </v-hover>
              </template>
              <span>Unknown status, click for copy</span>
            </v-tooltip>


          </template>


          <!--          <template v-slot:item.limitFilterType="{ item }">-->
          <!--            <LimitsEditor-->
          <!--                @update="getDataFromApi()"-->
          <!--                :key="item.campaign_id"-->
          <!--                :client_id="item.client_id"-->
          <!--                :campaignId="item.campaign_id"-->
          <!--                :limitFilterType="item.limitFilterType"-->
          <!--                :limitFilter_dailyLimit="item.limitFilter_dailyLimit"-->
          <!--                :limitFilter_overallLimit="item.limitFilter_overallLimit"-->
          <!--                :limitFilter_splitDailyLimitEvenly="item.limitFilter_splitDailyLimitEvenly"-->
          <!--            ></LimitsEditor>-->
          <!--          </template>-->
          <template v-slot:item.payment="{ item }">
            <span v-if="item.payment">{{ Math.round(item.payment / 100) }}</span>
            <span v-else></span>

          </template>
          <!--          <template v-slot:item.categoryType="{ item }">-->
          <!--            <v-tooltip bottom v-if="item.categoryType === 'dsp'">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <span v-on="on">DSP</span>-->
          <!--              </template>-->
          <!--              <span>DSP</span>-->
          <!--            </v-tooltip>-->

          <!--            <span v-else-if="item.cabName && item.cabName === 'DSP'">{{ item.name }}</span>-->
          <!--            <v-tooltip bottom v-else-if="item.status_name">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <span v-on="on" class="red lighten-2">{{ item.campaign_id }}</span>-->
          <!--              </template>-->
          <!--              <span>{{ item.status_name }}</span>-->
          <!--            </v-tooltip>-->
          <!--            <v-tooltip bottom v-else>-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <span v-on="on" class="grey lighten-2">{{ item.campaign_id }}</span>-->
          <!--              </template>-->
          <!--              <span>Unknown status</span>-->
          <!--            </v-tooltip>-->
          <!--          </template>-->

          <template v-slot:item.offer="{ item }">
            <v-tooltip bottom v-if="item.offer">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.offer }}</span>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
            <!--            <span v-if="item.offer">{{item.offer}}</span>-->
            <!--            <span v-else>{{item.name}}</span>-->
            <span v-else-if="item.cabName && item.cabName === 'DSP'">{{ item.name }}</span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="grey lighten-2">{{ item.name }}</span>
              </template>
              <span>Error format name in Campaign Name<br>
                Correct example : "UserName - OfferName_GEO ..."
              </span>
            </v-tooltip>
          </template>

          <template v-slot:item.who_added="{ item }">
            <v-hover
                v-if="!item.isReadyModeration"
                v-slot:default="{ hover }">
              <span
                  :class="{'text-decoration-underline': hover}"
                  :style="{'cursor': hover ? 'pointer': 'default'}"
                  @click.stop="whoAdded.push(item.who_added)"
              >{{ item.who_added }}</span>
            </v-hover>
          </template>

          <template v-slot:item.region="{ item }">
            <v-hover
                v-if="!item.isReadyModeration"
                v-slot:default="{ hover }">
              <span
                  :class="{'text-decoration-underline': hover}"
                  :style="{'cursor': hover ? 'pointer': 'default'}"
                  @click.stop="region = item.region"
              >{{ item.region }}</span>
            </v-hover>
          </template>


        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import api from '@/utils/api'
import {DateTime} from 'luxon'
import {
  IFACE_DST_SET_DEP, IFACE_DST_SET_DEPS,
  IFACE_SOURCE_CAMPAIGNS_ALLOW_REFRESH_OFF,
  IFACE_SOURCE_CAMPAIGNS_ALLOW_REFRESH_ON,
  IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_OFF,
  IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_ON,
  IFACE_SOURCE_CAMPAIGNS_LOADING_OFF,
  IFACE_SOURCE_CAMPAIGNS_LOADING_ON,
  IFACE_SOURCE_CAMPAIGNS_REFRESH_OFF,
  IFACE_SOURCE_CAMPAIGNS_REFRESH_ON,
} from '@/store/actions/iface'

// import LimitsEditor from './LimitsEditor'
// import StartStopMenu from './StartStopMenu'
import VideoPlayer from "@/components/DSP/Teasers/VideoPlayer.vue";
import Now from "@/components/Common/Now.vue"
import LastUpdateToolTip from "@/components/Common/LastUpdateToolTip.vue";

export default {
  name: 'Sources',
  components: {LastUpdateToolTip, VideoPlayer, Now},
  data() {
    return {
      tz: 'America/Los_Angeles',
      menu: false,
      searchName: null,
      searchTags: null,

      offer: null,
      allOffers: [],

      campaignId: null,
      campaignIds: [],

      teaserId: null,
      teaserIds: [],

      region: [],
      allRegions: [],

      dates: [],

      options: {itemsPerPage: 20},

      switch1: false,

      itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
      headers: [],

      sortBy: ['spent'],
      sortDesc: [true],

      headersForGroupByCampaign: [
        {text: 'Who added', value: 'who_added'},
        // {text: 'Creator', value: 'creator'},
        {text: 'Cab', value: 'cabName',},
        {text: 'Id', value: 'campaign_id'},
        {text: '', value: 'data-table-expand'},
        {text: 'Offer', value: 'offer'},
        {text: 'Tags', value: 'tags'},
        {text: 'Region', value: 'region'},
        {text: 'Budget', value: 'limitFilterType'},
        {text: 'Payment, $', value: 'payment'},
        // {text: '', value: 'categoryType'},
        // {text: 'CPC, $', value: 'cpc'},
        // {text: 'spent', value: 'spent'},
        // {text: 'sale', value: 'buying'},
        // {text: 'hold', value: 'decision'},
        // {text: 'reject', value: 'interest'},
        // {text: 'imps', value: 'imps'},
        // {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByCampaignWithAff: [
        {text: 'Who added', value: 'who_added'},
        // {text: 'Creator', value: 'creator'},
        {text: 'Cab', value: 'cabName',},
        {text: 'ID', value: 'campaign_id', align: 'center'},
        {text: 'A.Name', value: 'affNetworkName', align: 'center'},
        {text: 'Offer', value: 'offer'},
        {text: 'Tags', value: 'tags'},
        {text: 'Region', value: 'region'},
        {text: 'Budget', value: 'limitFilterType'},
        {text: 'Payment', value: 'payment'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        // {text: 'earning', value: 'earning'},
        {text: 'revenue', value: 'revenue'},
        {text: 'profit', value: 'profit'},
        {text: 'ROI,%', value: 'roi'},
        {text: 'sale', value: 'buying'},
        // {text: 'hold', value: 'decision'},
        // {text: 'reject', value: 'interest'},
        // {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByDate: [
        {text: 'Date', value: 'date'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        // {text: 'hold', value: 'decision'},
        // {text: 'reject', value: 'interest'},
        // {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByDateWithAff: [
        {text: 'Date', value: 'date'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'revenue', value: 'revenue'},
        {text: 'profit', value: 'profit'},
        {text: 'ROI,%', value: 'roi'},
        {text: 'sale', value: 'buying'},
        // {text: 'hold', value: 'decision'},
        // {text: 'reject', value: 'interest'},
        // {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByCreator: [
        {text: 'Creator', value: 'creator'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC,$', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByCab: [
        {text: 'Cab', value: 'cabName'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByRegion: [
        {text: 'Region', value: 'region'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByOffer: [
        {text: 'Offer', value: 'offer'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      headersForGroupByWhoAdded: [
        {text: 'Who Add', value: 'who_added'},
        {text: 'Campaigns', value: 'count'},
        {text: 'CPC, $', value: 'cpc'},
        {text: 'spent', value: 'spent'},
        {text: 'sale', value: 'buying'},
        {text: 'hold', value: 'decision'},
        {text: 'reject', value: 'interest'},
        {text: 'imps', value: 'imps'},
        {text: 'clicks', value: 'clicks'}
      ],

      expanded: [],
      statistic: [],
      total: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,

      totalCampaigns: 0,
      totalSpent: 0,
      totalCpc: 0,
      totalSale: 0,
      totalEarning: '',
      totalRevenue: '',
      totalProfit: '',
      totalRoi: '',
      totalHold: 0,
      totalReject: 0,
      totalImps: 0,
      totalClicks: 0,

      selectedFastDateRange: 0,
      selectedFastDateRangeItems: [
        {text: 'Today', value: 'today'},
        {text: 'Yesterday', value: 'yesterday'},
        {text: 'This month', value: 'thisMonth'},
        {text: 'Last month', value: 'lastMonth'},
      ],

      groupBy: 'Campaign',
      groupByItems: ['Campaign', 'Who Added', 'Creator', 'Offer', 'Region', 'Date', 'Cab'],

      campaignType: 'all',
      campaignTypes: [
        {value: 'all', text: 'All'},
        {value: 'product', text: 'Product', icon: 'mdi-cart-outline'},
        {value: 'search_feed', text: 'Search feed', icon: 'mdi-magnify'},
        {value: 'content', text: 'Content', icon: 'mdi-alpha-c-circle-outline'},
        {value: 'push', text: 'Push', icon: 'mdi-message-badge-outline'},
        {value: 'video', text: 'Video', icon: 'mdi-video'},
        {value: 'dsp', text: 'DSP', icon: 'mdi-alpha-d-circle-outline'}
      ],

      // whoAdded: ['My'],
      whoAdded: [],
      creator: ['My'],
      whoAddeds: [],
      creators: [],

      // cab: [{name: 'All', id: 'all'}],
      cab: [],

      cabs: [],

      affNetworkSelected: [],
      affNetworks: ['All', 'Tonic', 'Encoreads', 'Iceoffers', 'Jumbleberry', 'Oasis', 'DomainActive'],

      snackbar: false,
      snackbarText: '',

      confirmStartStopCampaign: false,
      toStartCampaign: null,

      // now: 0,
      timeoutLastUpdate: 30,// min,

      allowedDSPTeaserLinks: [],
      allowedDSPTeaserLinksRegexp: [],

      headersTeasers: [
        {
          text: '',
          class: 'my-header-fix',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: '',
          class: 'my-header-fix',
          align: 'center',
          sortable: false,
          value: 'url'
        },
        {
          text: '',
          class: 'my-header-fix', align: 'end', sortable: false, value: 'imageLink', width: '40%'
        },
      ],
      countries: [],
      pps: [],
      team: {value: []},
      owner: null,
      errorMessage: '',
      isVisibleValidationErrorDialog: false,
      copyTeasersLoading: false,
      copyTeasersResult: false,
      newTeasers: [],
      abTasksTeasers: [],
    }
  },

  async created() {
    const date = this.todayDate()
    this.dates = [date, date]

    // setInterval(() => {
    //   this.now = Math.round(Date.now() / 1000)
    //   // console.log(this.now)
    // }, 1000)
    //
    // const c = await api.getSpendingCatalog()
    // //
    // Vue.set(this, 'cabs', c.data.cabs)
    // Vue.set(this, 'cabs', c.data.cabs)
    // this.$set(this, 'whoAddeds', ['My'].concat(c.data.who_addeds))
    // console.log(c.data.whoAddeds)
    // Vue.set(this, 'creators', ['All', 'My'].concat(c.data.allCreatorsInfo))

    // if (this.$store.getters['user/getUserName'] === 'denis') {
    //   this.whoAdded = []
    // }
  },

  async mounted() {
    // this.campaignId = "11916343"
    this.updateCatalog()
    await this.getDataFromApi()

    // this.$nextTick(() => {
    //   const item = this.statistic.find(row => row.campaign_id === 11916343)
    //   if (item) {
    //     this.expanded.push(item)
    //     this.itemExpanded({item, value: true})
    //   }
    // })

  },

  computed: {
    dateRangeText() {
      if (this.isToday()) return 'Today'
      if (this.isYesterday()) return 'Yesterday'
      if (this.isThisMonth()) return 'This month'
      if (this.isLastMonth()) return 'Last month'
      return this.dates.join(' ~ ')
    },

    refreshTable: {
      get() {
        return this.$store.getters['iface/getRefreshSourcesCampaigns']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_REFRESH_ON)
        else
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_REFRESH_OFF)
      }
    },

    loading: {
      get() {
        return this.$store.getters['iface/getSourceCampaignsIsLoading']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_LOADING_ON)
        else
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_LOADING_OFF)
      }
    },

    isAllowRefreshCampaigns: {
      get() {
        return this.$store.getters['iface/getIsAllowRefreshSourcesCampaigns']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_ALLOW_REFRESH_ON)
        else
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_ALLOW_REFRESH_OFF)
      }
    },

    forceRefreshSourceCampaigns: {
      get() {
        return this.$store.getters['iface/getForceRefreshSourcesCampaigns']
      },
      set(value) {
        if (value)
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_ON)
        else
          this.$store.commit(IFACE_SOURCE_CAMPAIGNS_FORCE_REFRESH_OFF)
      }
    },

    isRunningBackdatingUpdateSpent: {
      get() {
        return this.$store.getters['iface/getIsRunningBackdatingUpdateSpent']
      }
    },

    depsAndTeams: {
      get() {
        return this.$store.getters['iface/getDspDeps']
      },
      set(value) {
        this.$store.commit(IFACE_DST_SET_DEPS, value)
      }
    },

    dep: {
      get() {
        return this.$store.getters['iface/getDspDep']
      },
      set(value) {
        this.$store.commit(IFACE_DST_SET_DEP, value)
      }
    },

    teams: {
      get() {
        return this.$store.getters['iface/getDspDepTeams'].value
      }
    },

    dspDep: {
      get() {
        return this.$store.getters['iface/getDspDep']
      },
      set(value) {
        this.$store.commit(IFACE_DST_SET_DEP, value)
      }
    },

    dspDepsAndTeams: {
      get() {
        return this.$store.getters['iface/getDspDeps']
      },
      set(value) {
        this.$store.commit(IFACE_DST_SET_DEPS, value)
      }
    },
    aggressionItems: () => [100, 90, 80, 70, 60, 50, 40, 30, 20, 10],
  },

  methods: {
    // now() {
    //   return Math.round(Date.now() / 1000)
    // },

    copyToClipboard(text) {
      this.$copyText(text.toString()).then(() => {
        this.snackbarText = text
        this.snackbar = true
      }, (e) => {
        console.log('Copy Error', e)
      })

    },

    todayDate() {
      // const c = DateTime.local().setZone('America/Los_Angeles').toISODate()
      // console.log('cccccccccccc', c, )
      return DateTime.local().setZone(this.tz).toISODate()
    },

    yesterdayDate() {
      return DateTime.local().setZone(this.tz).endOf('day').plus({days: -1}).toISODate()
    },

    thisMonth() {
      return DateTime.local().setZone(this.tz).startOf('month').toISODate()
    },

    isToday() {
      if (this.dates.length === 1)
        return this.todayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.todayDate() === this.dates[0] && this.todayDate() === this.dates[1]

      return false
    },

    isYesterday() {
      if (this.dates.length === 1)
        return this.yesterdayDate() === this.dates[0]
      if (this.dates.length === 2)
        return this.yesterdayDate() === this.dates[0] && this.yesterdayDate() === this.dates[1]

      return false
    },

    isThisMonth() {
      if (this.dates.length === 2)
        return this.thisMonth() === this.dates[0] && this.todayDate() === this.dates[1]
      return false
    },

    isLastMonth() {
      const startMonth = DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString()
      const endMonth = DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()

      if (this.dates.length === 2)
        return startMonth === this.dates[0] && endMonth === this.dates[1]
      return false
    },

    onClickFastDateInterval(value) {
      switch (value) {
        case 'today' :
          this.dates = [this.todayDate()]
          break
        case 'yesterday' :
          this.dates = [this.yesterdayDate()]
          break
        case 'thisMonth' :
          this.dates = [this.thisMonth(), this.todayDate()]
          break
        case 'lastMonth' :
          this.dates = [
            DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString(),
            DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()]
          break
        default:
          throw `Unknown ${value}`
      }
    },

    onclickOkPeekDate() {
      this.getDataFromApi()
    },

    onUpdateSortTable() {
      this.getDataFromApi()
    },

    async getDataFromApi(updateCampaigns) {
      // console.log('getDataFromApi')
      this.loading = true

      let startDate
      let endDate

      let whoAdded
      let creator
      let clientId
      let offer
      let region
      let campaignId
      let teaserId
      let tags

      let type

      if (this.campaignType !== 'all') {
        type = this.campaignType
      }

      // if (this.dates.length === 1) {
      //   startDate = this.dates[0]
      //   endDate = this.dates[0]
      // } else if (this.dates.length === 2) {
      //   startDate = this.dates[0]
      //   endDate = this.dates[1]
      // } else
      //   throw 'Error dates'
      //
      // if (this.switch1) {
      //   try {
      //     await api.runFetchCampaignsDayStatistic(startDate, endDate)
      //   } catch (e) {
      //     console.error('getDataFromApi', e)
      //   }
      //   this.switch1 = false
      //   this.loading = false
      //   return
      // }

      // if (this.creator === 'All') {
      //   creator = undefined
      // } else if (this.creator === 'My') {
      //   creator = this.$store.getters['user/getUserName']
      // } else {
      //   creator = this.creator
      // }

      if (Array.isArray(this.whoAdded)) {
        let resWhoAdded = []
        this.whoAdded.forEach(w => {
          if (w === 'My') {
            resWhoAdded.push(this.$store.getters['user/getUserName'])
          } else {
            resWhoAdded.push(w)
          }
        })

        if (resWhoAdded.length > 0) {
          whoAdded = resWhoAdded
        }
      }

      // if (this.whoAdded === 'All') {
      //   whoAdded = undefined
      // } else if (this.whoAdded === 'My') {
      //   whoAdded = this.$store.getters['user/getUserName']
      // } else {
      //   whoAdded = this.whoAdded
      // }

      // console.log('whoAdded', whoAdded)
      // console.log('this.cab.name', this.cab)

      if (Array.isArray(this.cab)) {
        let isForAll = false
        let cabs = []

        this.cab.forEach(cab => {
          if (cab.name === 'All') {
            isForAll = true
          } else {
            cabs.push(cab.id)
          }
        })

        if (!isForAll) {
          clientId = cabs
        }
      }


      if (this.region.length > 0)
        region = this.region

      if (this.offer)
        offer = this.offer

      if (this.campaignIds.length > 0) {
        campaignId = this.campaignIds
      } else if (this.campaignId) {
        campaignId = [this.campaignId]
      }

      if (this.teaserIds.length > 0) {
        teaserId = this.teaserIds
      } else if (this.teaserId) {
        teaserId = [this.teaserId]
      }

      let name

      if (this.searchName !== null) {
        name = this.searchName
      }

      if (this.searchTags !== null) {
        tags = this.searchTags.split(',')
      }

      let affNetworks
      if (Array.isArray(this.affNetworkSelected) && this.affNetworkSelected.length > 0) {
        // console.log(this.affNetworkSelected)
        if (this.affNetworkSelected.some(item => item === 'All')) {
          affNetworks = ['All']
        } else {
          affNetworks = this.affNetworkSelected
        }
      }

      if (this.groupBy !== 'Campaign') {
        const roiIndex = this.sortBy.findIndex(item => item === 'roi')
        if (roiIndex) {
          this.sortBy.splice(roiIndex, 1)
          this.sortDesc.splice(roiIndex, 1)
        }
      }

      switch (this.groupBy) {
        case 'Campaign':
          if (Array.isArray(this.affNetworkSelected) && this.affNetworkSelected.length === 0) {
            Vue.set(this, 'headers', this.headersForGroupByCampaign)
          } else {
            Vue.set(this, 'headers', this.headersForGroupByCampaignWithAff)
          }
          break
        case 'Date':
          if (Array.isArray(this.affNetworkSelected) && this.affNetworkSelected.length === 0) {
            Vue.set(this, 'headers', this.headersForGroupByDate)
          } else {
            Vue.set(this, 'headers', this.headersForGroupByDateWithAff)
          }
          break
        case 'Creator':
          Vue.set(this, 'headers', this.headersForGroupByCreator)
          break
        case 'Cab':
          Vue.set(this, 'headers', this.headersForGroupByCab)
          break
        case 'Region':
          Vue.set(this, 'headers', this.headersForGroupByRegion)
          break
        case 'Offer':
          Vue.set(this, 'headers', this.headersForGroupByOffer)
          break
        case 'Who Added':
          Vue.set(this, 'headers', this.headersForGroupByWhoAdded)
          break
      }

      // this.sortDesc, this.sortBy
      // const sortBy = this.sortBy.length > 0
      //   ?
      //   this.sortBy.reduce((acc, n, index) => {
      //     acc[n] = this.sortDesc[index]
      //     return acc
      //   }, {})
      //   : undefined
      //
      // console.log('sortBy', sortBy)

      const sortBy = this.sortBy.length > 0 ? this.sortBy : undefined
      const sortDesc = this.sortDesc.length > 0 ? this.sortDesc : undefined

      try {
        const res = await api.getSourceCampaigns({
          // , this.page - 1, startDate, endDate, this.groupBy, whoAdded, creator, clientId, name, sortBy, sortDesc, offer, region, campaignId
          sourceName: 'Mgid',
          updateCampaigns,
          limit: this.itemsPerPage,
          page: this.page - 1,
          startDate,
          endDate,
          groupBy: this.groupBy,
          whoAdded,
          creator,
          clientId,
          name,
          sortBy,
          sortDesc,
          offer,
          region,
          campaignId,
          teaserId,
          tags,
          affNetworks,
          type
        })
        // console.log('res', res)
        // this.$store.commit(IFACE_SPENDING_CAMPAIGNS_LAST_UPDATE, Math.round((Date.now() / 1000 - res.data.lastUpdate) / 60))
        this.$set(this, 'allOffers', res.data.offers)
        this.$set(this, 'allRegions', res.data.regions)
        this.$set(this, 'whoAddeds', ['My'].concat(res.data.whoAdded))
        this.$set(this, 'creators', ['My'].concat(res.data.creators))
        this.$set(this, 'total', res.data.count)

        if (Math.ceil(this.total / this.itemsPerPage) < this.page) {
          this.page = 1
        }

        res.data.statistic.forEach(e => {
          const row = this.statistic.find(s => e.campaign_id === s.campaign_id)
          if (row) {
            if (row.teasersData) {
              e.teasersData = row.teasersData
            } else {
              e.teasersData = {
                lastUpdate: 0,
                total: 0,
                page: 1,
                perPage: 20,
                pageCount: 0,
                limit: 10,
                loading: false,
                teasers: []
              }
            }
          } else {
            e.teasersData = {
              lastUpdate: 0,
              total: 0,
              page: 1,
              perPage: 20,
              pageCount: 0,
              limit: 10,
              loading: false,
              teasers: []
            }
          }
        })

        this.$set(this, 'statistic', res.data.statistic)

        if (teaserId && teaserId.length > 0) {
          console.log('statistic', teaserId, this.statistic)
          this.statistic.forEach(s => this.expanded.push(s))
        }

        // this.$set(this, 'totalCampaigns', res.data.totalCampaigns)
        // this.$set(this, 'totalSpent', res.data.spent)
        // this.$set(this, 'totalCpc', res.data.cpc)
        // this.$set(this, 'totalRevenue', res.data.revenue)
        // this.$set(this, 'totalRoi', res.data.roi)
        // this.$set(this, 'totalProfit', res.data.profit)
        // this.$set(this, 'totalSale', res.data.buying)
        // this.$set(this, 'totalReject', res.data.interest)
        // this.$set(this, 'totalHold', res.data.decision)
        // this.$set(this, 'totalImps', res.data.imps)
        // this.$set(this, 'totalClicks', res.data.clicks)

        // if ('fetchStatus' in res.data) {
        //   this.$store.commit(IFACE_SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS, res.data.fetchStatus)
        // } else {
        //   if (this.isRunningBackdatingUpdateSpent) {
        //     this.$store.commit(IFACE_RUNNING_BACKDATING_UPDATE_SPENT_OFF)
        //   }
        // }

      } catch (e) {
        console.error('getDataFromApi', e)
      }
      this.loading = false
      this.refreshTable = false
      this.forceRefreshSourceCampaigns = false
    },


    async updateCatalog() {
      let catalog
      try {
        // catalog = await api.getCatalogForTeasers(this.$route.params.section)
        catalog = await api.getSourceCatalog(this.$route.params.section)
      } catch (e) {
        console.error('created', e)
        return
      }

      // console.log('created catalog', catalog)
      // const teams = catalog.data.teams.reduce((acc, {name, users}) => {
      //   acc[name] = JSON.parse(users)
      //   return acc
      // }, {})

      // console.log('created offer', this.offer)
      // console.log('created catalog', catalog)
      // console.log('created copy', this.copy)
      // console.log('created ro', this.ro)
      // console.log('created owners', this.ro)

      this.$set(this, 'countries', catalog.data.countries)
      this.$set(this, 'cabs', catalog.data.cabs.map(c => {
        c.name = c.name + ' - ' +c.id
        return c}))
      this.$set(this, 'allowedDSPTeaserLinks', catalog.data.allowedDSPTeaserLinks)
      this.allowedDSPTeaserLinksRegexp = this.allowedDSPTeaserLinks.map(l => new RegExp(`^https://${l}`))
      // this.$set(this, 'languages', catalog.data.languages)
      // this.$set(this, 'languagesISO', catalog.data.languagesISO)
      // this.$set(this, 'offers', catalog.data.offers)
      // this.$set(this, 'iabCategories', catalog.data.iabCategories)
      // this.$set(this, 'landingTypes', catalog.data.landingTypes)
      this.$set(this, 'pps', catalog.data.pps)
      // this.$set(this, 'tags', catalog.data.tags)
      // this.$set(this, 'userAgentList', catalog.data.ua)
      // this.$set(this, 'allowedDSPTeaserLinks', catalog.data.allowedDSPTeaserLinks)
      // this.$set(this, 'dspModes', catalog.data.dspModes)
      // this.$set(this, 'sources', catalog.data.sources)
      // this.$set(this, 'imgHosts', catalog.data.imgHosts)
      // this.$set(this, 'depsSources', catalog.data.imgHosts)

      // this.$set(this, 'depsTeams', catalog.data.depsTeams)

      this.depsAndTeams = catalog.data.depsTeams

      if (this.depsAndTeams.length === 1) {
        this.dep = this.depsAndTeams[0]
      }

    },

    insertCopyTeaser(teasersData, teaser, index) {
      // console.log(teasersData, teaser, index)
      const t = {
        '$type': 'teaserForCopy',
        action: 'copy',
        isValid: false,
        isValidFullOfferName: false,
        offerValidationErrors: false,
        create: false,
        dep: null,
        team: null,
        owner: null,
        country: null,
        payout: 0,
        url: null,
        offer: null,
        offer_id: null,
        teaserId: null,
        pp: null
      }
      if (teaser['$type'] === 'teaser') {
        t.srcTeaser = teaser
        teaser.teasersForCopy.push(t)
        teasersData.teasers.splice(index + 1, 0, t)
      } else {
        teaser.srcTeaser.teasersForCopy.push(t)
        t.srcTeaser = teaser.srcTeaser
        teasersData.teasers.splice(index + 1, 0, t)
      }
    },

    deleteCopyTeaser(teasersData, teaser, index) {
      const res = teaser.srcTeaser.teasersForCopy.findIndex(t => t === teaser)
      if (res > -1) {
        teaser.srcTeaser.teasersForCopy.splice(res, 1)
      }
      teasersData.teasers.splice(index, 1)
    },

    remove(item) {
      this.affNetworkSelected.splice(this.affNetworkSelected.indexOf(item), 1)
    },

    async itemExpanded({item, value}) {
      if (value) {
        await this.refreshTeasers(item)

        // console.log('itemExpanded', this.expanded)
      }
    },

    async refreshTeasers(item, updateTeasers = false) {
      let teaserId

      if (this.teaserIds.length > 0) {
        teaserId = this.teaserIds
      } else if (this.teaserId) {
        teaserId = [this.teaserId]
      }
      const r = {
        sourceName: 'Mgid',
        limit: item.teasersData.limit,
        page: item.teasersData.page - 1,
        updateTeasers,
        clientId: item.client_id,
        campaignId: item.campaign_id,
        teaserId
      }

      item.teasersData.loading = true

      const teasers = await this.getTeasers(r)

      item.teasersData.total = teasers.total
      // console.log('teasersData',teasers.total, teasers.total / item.teasersData.limit, item.teasersData.page)
      if (Math.ceil(teasers.total / item.teasersData.limit) < item.teasersData.page) {
        item.teasersData.page = 1
      }
      item.teasersData.lastUpdate = teasers.campaignlastUpdate
      item.teasersData.teasers = teasers.teasers.map(t => {
        t['$type'] = 'teaser'
        t.teasersForCopy = [{
          action: 'copy',
          isValid: false,
          isValidFullOfferName: false,
          offerValidationErrors: false,
          create: false,
          dep: null,
          team: null,
          owner: null,
          country: null,
          payout: 0,
          url: null,
          offer: null,
          offer_id: null,
          teaserId: null,
          pp: null
        }]
        return t
      })

      item.teasersData.loading = false

      await this.getDataFromApi()

    },

    async getTeasers(r) {
      this.loading = true
      try {
        const res = await api.getSourceCampaignTeasers(r)
        res.data.teasers.forEach(e => {

          if (e.imageLink.match(/\.mp4$/i)) {
            e.videoOptions = {
              fluid: true,
              autoplay: true,
              controls: false,
              sources: [
                {
                  src: e.imageLink,
                  type: 'video/mp4'
                }
              ],
              loop: true
            }
          }

          try {
            e.previewLinks = JSON.parse(e.previewLinks)
          } catch (e) {
            e.previewLinks = {}
          }

          try {
            e.priceOfClickByLocations = JSON.parse(e.priceOfClickByLocations)
          } catch (e) {
            e.priceOfClickByLocations = {}
          }

          try {
            e.statusReason_if_drop_karantin = JSON.parse(e.statusReason_if_drop_karantin)
          } catch (e) {
            e.statusReason_if_drop_karantin = []
          }

          try {
            e.tags = JSON.parse(e.tags)
          } catch (e) {
            e.tags = {}
          }


        })
        return res.data
      } catch (e) {
        console.error('getTeasers', e)
      } finally {
        this.loading = false
      }
    },

    validateDep(v) {
      if (v !== null && typeof v === 'object' && v.text) {
        return true
      }
      return 'Empty'
    },
    validateTeam(v) {
      if (v !== null && typeof v === 'object' && v.text) {
        return true
      }
      return 'Empty'
    },

    validateOwner(v) {
      if (v !== null && typeof v === 'string' && v.length > 0) {
        return true
      }
      return 'Empty'
    },
    validateCountry(v) {
      if (v !== null && typeof v === 'string' && v.length > 0) {
        return true
      }
      return 'Empty'
    },
    validatePayout(v) {

      const intThisPayout = Math.round(parseInt(v * 10000))
      if (Number.isFinite(intThisPayout)) {
        // console.log(v, typeof v, intThisPayout)
        if (Number(intThisPayout) === intThisPayout && intThisPayout % 1 === 0 && (intThisPayout > 0)) {
          return true
        }
      } else {
        const f = parseFloat(v)
        // console.log(v, typeof f, f)
        // console.log('allowCreateTask f', f)
        if (Number(f) === f && f % 1 === 0 && f > 0) {
          return true
        }
      }
      return 'Error'
    },

    validateURL(value) {
      // if (v !== null && typeof v === 'string' && v.length > 0 && v.match(/^https:\/\//)) {
      //   return true
      // }
      // return 'Empty'

      if (value === null || value === undefined || value.toString().length === 0) {
        return 'Set valid URL'
      }
      if (/ +/.test(value)) {
        return 'Delete blank symbols'
      }
      if (!(/^https:\/\//.test(value))) {
        return 'must be https://'
      }
      if (
          // best.peekclickshndrk.com
          // www.shndrktrk.com
          // track.trcsrchd.xyz
          // www.bestbloglk.com
          /best\.peekclickshndrk\.com/.test(value) ||
          /www\.shndrktrk\.com/.test(value) ||
          /track\.trcsrchd\.xyz/.test(value) ||
          /www\.bestbloglk\.com/.test(value) ||
          /www\.btstracklnk\.com/.test(value)
      ) {
        return 'Invalid Domain'
      } else {
        if (this.allowedDSPTeaserLinksRegexp.some(r => r.test(value))) {
          return true
        }
        return 'Not allowed URL'
      }
    },
    setURL(v) {
      const splited = v.split('?')
      if (splited.length > 0) {
        this.thisLink_2 = splited[0].toString()
      }
    },

    validateOffer(v) {
      if (v !== null && typeof v === 'string' && v.length > 0 && v.match(/^[a-z]+$/)) {
        return true
      }
      return 'Empty'
    },

    validateOfferId(v) {
      if (v !== null && typeof v === 'string' && v.length > 0 && v.match(/^\d+$/)) {
        return true
      }
      return 'Empty'
    },

    validatePp(v) {
      if (v !== null && typeof v === 'string' && v.length > 0) {
        return true
      }
      return 'Empty'
    },

    validateTeaserId(v) {
      if (v !== null && typeof v === 'string' && v.length > 0 && v.match(/^\d+$/)) {
        return true
      }
      return 'Empty'
    },

    isVisibleCopyTeaser(v) {
      // console.log(v)
      // console.log(['onModeration','rejected'].includes(v))
      return ['onModeration', 'rejected'].includes(v)
      // return false
    },

    async onChangeCampaignId(val) {
      // console.log('onChangeCampaignId', val)
      if (typeof val === 'string') {
        const ids = val.split(',')
        if (ids.length > 1) {
          this.campaignIds = ids.map(id => id.trim())
        } else {
          this.campaignIds = []
        }
      } else {
        this.campaignIds = []
      }

      // console.log('campaignId', this.campaignId, this.campaignIds)
      await this.getDataFromApi()
      this.expanded = []
    },

    async onChangeTeaserId(val) {
      // console.log('onChangeTeaserId', val)
      if (typeof val === 'string') {
        const ids = val.split(',')
        if (ids.length > 1) {
          this.teaserIds = ids.map(id => id.trim())
        } else {
          this.teaserIds = []
        }
      } else {
        this.teaserIds = []
      }

      // console.log('teaserId', this.teaserId, this.teaserIds)
      this.expanded = []
      await this.getDataFromApi()

      if (this.expanded.length > 0) {
        this.expanded.forEach(e => this.refreshTeasers(e))
      }

    },

    totalTeaserAndCopy(teasers) {
      let totalTeasersForCopy = 0
      let totalCopy = 0
      teasers.forEach(t => {
        if (t.teasersForCopy) {
          const teasersForCopy = t.teasersForCopy.reduce((acc, c) => {
            if (c.create) {
              acc++
            }
            return acc
          }, 0)
          if (teasersForCopy > 0) {
            totalTeasersForCopy++
            totalCopy += teasersForCopy
          }
        }
      })
      return `${totalTeasersForCopy} / ${totalCopy}`
    },

    totalCopy(teasers) {
      let totalCopy = 0
      teasers.forEach(t => {
        if (t.teasersForCopy) {
          const teasersForCopy = t.teasersForCopy.reduce((acc, c) => {
            if (c.create) {
              acc++
            }
            return acc
          }, 0)
          if (teasersForCopy > 0) {
            totalCopy += teasersForCopy
          }
        }
      })
      return totalCopy
    },

    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
          (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    },

    async validateFullOfferName(item) {
      // console.log('validateFullOfferName', item)
      // item.isValidFullOfferName = true

      try {
        const r = (await api.dspTeaserValidate({
          kind: 'offer',
          networkName: 'Mgid',
          offer: `${item.offer}${item.offer_id}_${item.country}`
        })).data
        if (!r.error) {
          item.isValidFullOfferName = true
        } else {
          let msg = ''
          if (r.main) {
            msg += 'Exists offer_id:' + r.main.map(({id}) => id).join(',') + '<BR>'
          }

          if (r.moderation) {
            msg += 'Exists offer_id onModeration:' + r.moderation.map(({id}) => id).join(',')
          }

          this.errorMessage = msg
          this.isVisibleValidationErrorDialog = true
        }
        console.log('validateFullOfferName', r)
        //
      } catch (e) {
        console.error('validateFullOfferName', e)
      }
    },

    async copyTeasers(campaign) {
      this.copyTeasersLoading = true
      // console.log('copyTeasers', campaign)

      const campaign_id = campaign.campaign_id

      const allTeasersForCopy = []
      campaign.teasersData.teasers.forEach(teaser => {
        if (teaser['$type'] === 'teaser') {
          teaser.teasersForCopy.forEach(t => {
            if (t.create) {
              // console.log(t)
              if (teaser.action === 'copy') {
                allTeasersForCopy.push({
                  action: 'copy',
                  campaign_id,
                  teaser_id: teaser.id,
                  dep: t.dep.text,
                  teamId: t.team.id,
                  teamName: t.team.text,
                  owner: t.owner,
                  country: t.country,
                  payout: t.payout,
                  url: t.url,
                  offer: `${t.offer}${t.offer_id}_${t.country}`,
                  pp: t.pp
                })
              } else if (teaser.action === 'taskToA') {
                t.taskId = this.uuidv4()
                allTeasersForCopy.push({
                  action: 'taskToA',
                  taskId: t.taskId,
                  campaign_id,
                  teaser_id: teaser.id,
                  targetTeaser_id: Number(t.teaserId)
                })
              } else if (teaser.action === 'taskToB') {
                t.taskId = this.uuidv4()
                allTeasersForCopy.push({
                  action: 'taskToB',
                  taskId: t.taskId,
                  campaign_id,
                  teaser_id: teaser.id,
                  targetTeaser_id: Number(t.teaserId)
                })
              }
            }
          })
        }
      })
      // this.copyTeasersLoading = false
      // console.log('copyTeasers', campaign, allTeasersForCopy)
      // return

      try {
        const r = (await api.sourceCopyTeaser({sourceName: 'Mgid', copy: allTeasersForCopy})).data
        // console.log('copyTeasers', r)
        // await new Promise(resolve => setTimeout(resolve, 3000))
        // const r = {
        //   "copyRes": [
        //     // {
        //     //   "offer": "dsd11_ae",
        //     //   "error": "Duplicate entry 'ae-dsd11_ae' for key 'vertical'"
        //     // },
        //     {
        //       "offer": "dsd11_ae",
        //       "id": "667793",
        //     }
        //   ]
        // }
        if (r.error) {
          this.errorMessage = r.msg
          this.isVisibleValidationErrorDialog = true
        } else {
          this.copyTeasersResult = true

          const copyRes = r.copyRes
          campaign.teasersData.teasers.forEach(teaser => {
            if (teaser['$type'] === 'teaser') {
              teaser.teasersForCopy.forEach(t => {
                if (t.create) {
                  const offer = `${t.offer}${t.offer_id}_${t.country}`
                  const rCopy = copyRes.find((item) => (item.offer === offer || (item.taskId !== undefined && t.taskId === item.taskId)))

                  if (rCopy.error) {
                    t.newTeaserError = rCopy.error
                  } else {
                    if (t.taskId) {
                      this.abTasksTeasers.push(rCopy.id)
                    } else {
                      t.newTeaserId = rCopy.id
                      this.newTeasers.push(rCopy.id)
                    }
                  }
                }
              })
            }
          })
        }
        //
      } catch (e) {
        console.error('copyTeasers', e)
      } finally {
        this.copyTeasersLoading = false
      }
    },
    resetCopyTeasers(campaign) {
      campaign.teasersData.teasers.forEach(teaser => {
        if (teaser['$type'] === 'teaser') {
          teaser.teasersForCopy.forEach(t => {
            if (t.create && (t.newTeaserError || t.newTeaserId)) {
              Vue.delete(t, 'newTeaserError')
              Vue.delete(t, 'newTeaserId')
            }
          })
        }
      })
      this.copyTeasersLoading = false
      this.copyTeasersResult = false
      this.newTeasers = []
      this.abTasksTeasers = []
    },

    getNewTeasersLink() {
      return `/DSP/Teasers/Mgid?id=${this.newTeasers.join(',')}&onModerate=true&status=Wait`
    },

    getTeasersLink() {
      return `/DSP/Teasers/Mgid?id=${this.abTasksTeasers.join(',')}`
    },

    getNewTeaserLink(id) {
      return `/DSP/Teasers/Mgid?id=${id}&onModerate=true&status=Wait`
    },

    getTeaserLink(id) {
      return `/DSP/Teasers/Mgid?id=${id}`
    }
  },

  watch: {
    page() {
      this.expanded = []
      this.getDataFromApi()
    },

    itemsPerPage() {
      this.expanded = []
      // this.$set(this.options, 'itemsPerPage', parseInt(value, 10))
      // this.itemsPerPage = parseInt(value, 10)
      this.getDataFromApi()
    },

    whoAdded() {
      this.expanded = []
      this.getDataFromApi()
    },

    creator() {
      this.expanded = []
      this.getDataFromApi()
    },

    groupBy() {
      this.expanded = []
      this.getDataFromApi()
    },

    cab(val) {
      this.expanded = []
      // console.log('cab',val)
      if (Array.isArray(val) && val.length === 1) {
        this.isAllowRefreshCampaigns = true
      } else {
        this.isAllowRefreshCampaigns = false
      }
      this.getDataFromApi()
    },

    offer() {
      this.expanded = []
      this.getDataFromApi()
    },

    region() {
      this.expanded = []
      this.getDataFromApi()
    },

    searchName() {
      this.expanded = []
      this.getDataFromApi()
    },

    // async campaignId(val) {
    //   const ids = val.split(',')
    //   if (ids.length > 1) {
    //     this.campaignIds = ids
    //   } else {
    //     this.campaignIds = []
    //   }
    //
    //   console.log('campaignId', this.campaignId, this.campaignIds)
    //   // await this.getDataFromApi()
    //   this.expanded = []
    // },

    refreshTable(value) {
      if (value) {
        this.getDataFromApi()
      }
    },

    forceRefreshSourceCampaigns(value) {
      // console.log('forceRefreshSourceCampaigns', value)
      if (value) {
        this.getDataFromApi(true)
      }
    },

    searchTags() {
      this.expanded = []
      this.getDataFromApi()
    },

    campaignType() {
      this.expanded = []
      this.getDataFromApi()
    },

    // affNetworkSelected() {
    //   // if (Array.isArray(value) && value.length === 0) {
    //   //   Vue.set(this, 'headers', this.headersForGroupByCampaign)
    //   // } else {
    //   //   Vue.set(this, 'headers', this.headersForGroupByCampaignWithAff)
    //   // }
    //   this.getDataFromApi()
    // }
  }
}
</script>

<style scoped>
.my-header-fix /deep/ {
  height: 0;
}
</style>