<script>

export default {
  name: "LastUpdateToolTip",
  props: {
    lastUpdate: Number
  },

  data() {
    return {
      now: 0
    }
  },
  created() {
    setInterval(() => {
      this.now = Math.round(Date.now() / 1000)
      // this.$emit('now', this.now)
    }, 1000)
  }
}
</script>

<template>
  <span >Updated {{ (Math.round((now - lastUpdate ) / 60)) }} minutes ago.</span>
</template>

<style scoped>

</style>